/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";

import Icon from "../../Icon";

import {
  footerSocialsStyles,
  footerSocialsListStyles,
  footerSocialsListItemLinkStyles,
} from "./styles";

interface IFooterSocialsProps {
  isVisible: boolean;
}

function FooterSocials({ isVisible }: IFooterSocialsProps) {
  const { t } = useTranslation();

  return (
    <div css={footerSocialsStyles}>
      <ul css={footerSocialsListStyles}>
        {(t("footer.socials.list", { returnObjects: true }) as []).map(
          ({ icon, link, alt }, index) => (
            <li key={`socialsListItem-${index.toString()}`}>
              <a
                href={
                  icon === "social_mailto"
                    ? `mailto:?subject=${t("title")} ${t(
                        "subtitle"
                      )}&body=${link}`
                    : link
                }
                target="_blank"
                rel="noreferrer"
                aria-label={alt}
                title={alt}
                css={footerSocialsListItemLinkStyles}
                tabIndex={isVisible ? 0 : -1}
              >
                <Icon
                  icon={icon}
                  size={18}
                  viewBox={
                    icon === "social_mailto"
                      ? "0 0 305.616 305.616"
                      : "0 0 24 24"
                  }
                />
              </a>
            </li>
          )
        )}
      </ul>
    </div>
  );
}

export default FooterSocials;
