import { spacingMd } from "../../assets/styles/spacing";
import { maxLayoutWidth } from "../../assets/styles/global";
import { footerBackgroundColor } from "../../assets/styles/colors";

export const footerStyles = {
  padding: spacingMd,
  backgroundColor: footerBackgroundColor,
};

export const footerContainerStyles = {
  margin: "auto",
  display: "flex",
  alignItems: "center",
  maxWidth: maxLayoutWidth,
  justifyContent: "space-between",
  flexDirection: "column" as const,
};
