//= ========================================================
// Imports
//= ========================================================

// STEP 1:
// Import all images that need to be preloaded.
// NB: Images must be located in src/images, NOT in public/images.

// Homepage
import experience1 from "../assets/images/experience1.jpg";
import experience2 from "../assets/images/experience2.jpg";
import experience3 from "../assets/images/experience3.jpg";
import experience4 from "../assets/images/experience4.jpg";

//= ========================================================
// Images to preload data
//= ========================================================

// STEP 2:
// Add all of the imported images to the below map.
//
// A map of all the images we want to preload.
// NB: Maps image keys to image src paths.
export const imagesToPreloadData = {
  // Home page
  experience1,
  experience2,
  experience3,
  experience4,
};
