/** @jsxImportSource @emotion/react */

import { StyledButton, StyledLink } from "./styles";

interface IButtonProps {
  label: string;
  href?: string;
  isSkip?: boolean;
  isSecondary?: boolean;
  onClick?: () => void;
  tabIndex?: number;
  aria?: {
    expanded?: boolean;
    controls?: string;
  };
  title?: string;
}

function Button({
  href,
  label,
  isSkip = false,
  isSecondary = false,
  tabIndex = 0,
  onClick,
  aria,
  title,
}: IButtonProps) {
  return href ? (
    <StyledLink
      target="_blank"
      href={href}
      isSecondary={isSecondary}
      tabIndex={tabIndex}
      title={title}
    >
      {label}
    </StyledLink>
  ) : (
    <StyledButton
      isSkip={isSkip}
      onClick={onClick}
      isSecondary={isSecondary}
      tabIndex={tabIndex}
      aria-expanded={aria?.expanded}
      aria-controls={aria?.controls}
      title={title}
    >
      {label}
    </StyledButton>
  );
}

export default Button;
