/* eslint-disable no-console */
/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { useLayoutEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import Main from "../components/Main";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Section from "../components/Section";
import PageIntro from "../components/PageIntro";
import Experience from "../components/Experience";
import BackgroundShape from "../components/BackgroundShape";

import {
  brandColorBlue,
  brandColorGreen,
  brandColorYellow,
} from "../assets/styles/colors";
import useWindowWidth from "../utils/useWindowWidth";
import useMobileDetect from "../utils/useMobileDetect";

function Home() {
  const { t } = useTranslation();
  const [isWindowWidthMobile, setWindowWidthMobile] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const hideBannerDate = new Date("06/09/2024 09:00:00 PM");
  const [showBanner] = useState(new Date() < hideBannerDate);

  useWindowWidth(setWindowWidthMobile);
  useMobileDetect(setIsMobile);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (document.querySelectorAll("#cookie-policy").length > 1)
        document.querySelectorAll("#cookie-policy")[1].remove();
    }, 2000);
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {t("title")} {t("subtitle")}
          </title>

          <meta
            name="description"
            content={`${t("title")} ${t("subtitle")} - ${t("intro.title")}`}
          />

          <script
            defer
            type="text/javascript"
            data-tracker-name="ATInternet"
            data-value="https://www.europarl.europa.eu/website/webanalytics/ati-digital-citizen-journey.js"
            src="https://www.europarl.europa.eu/website/privacy-policy/privacy-policy.js"
          />
        </Helmet>
      </HelmetProvider>

      <Header />
      <Main>
        {showBanner && <Banner />}
        <PageIntro />
        <BackgroundShape
          delay={isWindowWidthMobile ? 0 : 2}
          isAlignRight
          color={brandColorYellow}
          size={isWindowWidthMobile ? 800 : 1200}
          topPosition={isWindowWidthMobile ? 13 : 7}
          sidePosition={isWindowWidthMobile ? 40 : 60}
          className="background-shape__1"
        />
        <BackgroundShape
          delay={3}
          color={brandColorBlue}
          size={isWindowWidthMobile ? 1000 : 1900}
          topPosition={isWindowWidthMobile ? 35 : 28}
          sidePosition={isWindowWidthMobile ? -80 : -60}
          className="background-shape__2"
        />

        <BackgroundShape
          delay={2}
          isAlignRight
          color={brandColorGreen}
          size={isWindowWidthMobile ? 1200 : 2000}
          topPosition={isWindowWidthMobile ? 65 : 60}
          sidePosition={isWindowWidthMobile ? 40 : 50}
          className="background-shape__3"
        />
        <BackgroundShape
          delay={1}
          color={brandColorYellow}
          size={isWindowWidthMobile ? 700 : 1000}
          topPosition={isWindowWidthMobile ? 98 : 95}
          sidePosition={isWindowWidthMobile ? -80 : -20}
          className="background-shape__4"
        />

        {(t("sections", { returnObjects: true }) as []).map(
          ({ experiences }, sectionIndex) => (
            <Section
              key={`section-${sectionIndex.toString()}`}
              className="experience-section"
            >
              {(experiences as []).map(
                (
                  { name, text, visual, actions, isComingSoon, duration },
                  index
                ) => (
                  <Experience
                    name={name}
                    text={text}
                    visual={visual}
                    duration={duration}
                    actions={actions}
                    isComingSoon={isComingSoon}
                    isInverted={index % 2 === 1}
                    isMobile={isMobile}
                    key={`experience-${index.toString()}`}
                    isFirst={sectionIndex === 0 && index === 0}
                    id={`experience-${index.toString()}`}
                  />
                )
              )}
            </Section>
          )
        )}
      </Main>
      <Footer isVisible />
    </>
  );
}

export default Home;
