/** @jsxImportSource @emotion/react */
import {
  motion,
  useTransform,
  useAnimation,
  useViewportScroll,
} from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

import Button from "../Button";
import Actions from "../Actions";
import Columns from "../Columns";
import ModalShare from "../ModalShare";

import {
  experienceStyles,
  experienceComingSoonStyles,
  StyledExperienceVisual,
} from "./styles";
import { experienceVariants, experienceImageVariants } from "./variants";

import { Link } from "../../utils/interface";
import useWindowWidth from "../../utils/useWindowWidth";
import useRefScrollProgress from "../../utils/useRefScrollProgress";
import imageManager, { imagesEnum } from "../../utils/ICFNext/ImageManager";

interface IExperienceActionsProps {
  start: Link;
  share: Link;
  startBrowser: Link;
}

interface IExperienceProps {
  id: string;
  name: string;
  text: string;
  isFirst: boolean;
  isInverted?: boolean;
  isComingSoon?: boolean;
  isMobile: boolean;
  actions: IExperienceActionsProps;
  visual: "experience1" | "experience2" | "experience3" | "experience4";
  duration?: string;
}

function Experience({
  id,
  name,
  text,
  visual,
  duration,
  actions,
  isFirst,
  isMobile = false,
  isInverted = false,
  isComingSoon = false,
}: IExperienceProps) {
  const { t } = useTranslation();
  const controls = useAnimation();
  const { scrollYProgress } = useViewportScroll();

  const [ref, inView] = useInView({ threshold: 0.2 });
  const [refVisual, start, end] = useRefScrollProgress();

  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [isWindowWidthMobile, setWindowWidthMobile] = useState(false);

  const visualPreloaded = imageManager.getPreloadedImgSrc(imagesEnum[visual]);

  const toggleModal = () => setShareModalOpen(!isShareModalOpen);

  const yPosAnim = useTransform(
    scrollYProgress,
    [start, end],
    isWindowWidthMobile ? [10, 0] : [20, -25]
  );

  useEffect(() => {
    if (inView) controls.start("visible");
  }, [inView]);

  useWindowWidth(setWindowWidthMobile);

  const getEnvURL = (url: string) => {
    const ipRegex =
      /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/;
    const d = window.location.hostname;
    const b = "digital-journey.europarl.europa.eu";
    if (url.includes(b)) {
      if (d.startsWith("dev.") || d.startsWith("qua.")) {
        return url.replace(b, d);
      } else if (!url.includes("qua.") && ipRegex.test(d)) {
        return url.replace(b, `qua.${b}`);
      }
    }
    return url;
  };

  return (
    <div css={experienceStyles} ref={ref} className="experience">
      <motion.div
        initial="hidden"
        animate={controls}
        variants={experienceVariants(isFirst, isInverted)}
      >
        <Columns columns={[60, 40]} isInverted={isInverted}>
          <StyledExperienceVisual isInverted={isInverted}>
            <motion.div
              ref={refVisual}
              initial="hidden"
              animate={controls}
              style={{ y: yPosAnim }}
              variants={experienceImageVariants(isFirst, isInverted)}
            >
              <img
                src={visualPreloaded}
                alt={name}
                title={name}
                width="900"
                height="550"
              />
            </motion.div>
          </StyledExperienceVisual>

          <div>
            {isComingSoon && (
              <p css={experienceComingSoonStyles}>
                <strong>{t("comingSoon")}</strong>
              </p>
            )}
            <h3>{name}</h3>
            {duration && <h4>{duration}</h4>}
            <p>{text}</p>
            <Actions>
              {actions.start.isActive &&
                !(actions.start.isAvailableForMobile === false && isMobile) && (
                  <Button
                    href={getEnvURL(actions.start.link)}
                    label={actions.start.label}
                    title={name}
                  />
                )}
              {actions.startBrowser &&
                actions.startBrowser.isActive &&
                (actions.startBrowser.isAvailableForMobile === true ||
                  (actions.startBrowser.isAvailableForMobile === false &&
                    !isMobile)) && (
                  <Button
                    isSecondary
                    href={getEnvURL(actions.startBrowser.link)}
                    label={actions.startBrowser.label}
                    title={name}
                  />
                )}
              {actions.share.isActive &&
                !(actions.start.isAvailableForMobile === false && isMobile) && (
                  <Button
                    isSecondary
                    onClick={toggleModal}
                    label={actions.share.label}
                    aria={{
                      expanded: isShareModalOpen,
                      controls: `${id}-shareModal`,
                    }}
                  />
                )}
            </Actions>
          </div>
        </Columns>
      </motion.div>

      <ModalShare
        id={`${id}-shareModal`}
        link={getEnvURL(actions.share.link)}
        isOpen={isShareModalOpen}
        subject={actions.share.subject}
        toggleModal={toggleModal}
      />
    </div>
  );
}

export default Experience;
