import { mq } from "../../assets/styles/mediaqueries";
import { spacingLg, spacingMd } from "../../assets/styles/spacing";
import { brandColorBlue, defaultTextColor } from "../../assets/styles/colors";

export const modalShareStyles = {
  width: "100%",
  display: "flex",
  padding: spacingLg,
  flexDirection: "column" as const,

  [mq[1]]: {
    padding: spacingMd + spacingLg,
  },
};

export const modalShareListStyles = {
  display: "flex",
  paddingTop: spacingMd,
  flexDirection: "row" as const,
  justifyContent: "space-between",
};

export const modalShareListButtonStyles = {
  width: 45,
  height: 45,
  display: "flex",
  paddingBottom: 0,
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  border: `1px solid ${defaultTextColor} !important`,
  transition: "0.7s cubic-bezier(0.785, 0.135, 0.15, 0.86)",

  "&:hover": {
    backgroundColor: `${defaultTextColor} !important`,

    path: {
      fill: `${brandColorBlue}`,
    },
  },
};
