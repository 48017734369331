import styled from "@emotion/styled";

import {
  spacingSm,
  spacingMd,
  spacingLg,
  spacingXLg,
  spacingXXLg,
} from "../../assets/styles/spacing";
import { mq } from "../../assets/styles/mediaqueries";
import { boxShadow } from "../../assets/styles/colors";

export const experienceStyles = {
  paddingTop: spacingMd,
  paddingBottom: spacingMd,

  [mq[2]]: {
    paddingTop: spacingLg,
    paddingBottom: spacingXLg,
  },

  "& + &": {
    marginTop: spacingLg,

    [mq[2]]: {
      marginTop: spacingLg,
    },
  },
};

export const experienceTitleStyles = {
  margin: 0,
  maxWidth: "100%",
  paddingBottom: spacingMd,

  [mq[1]]: {
    maxWidth: "75%",
    paddingBottom: spacingLg,
    paddingLeft: spacingSm * 4,
  },

  [mq[2]]: {
    maxWidth: "55%",
    paddingLeft: spacingXXLg,
    paddingBottom: spacingSm * 4,
  },

  [mq[3]]: {
    paddingLeft: spacingSm * 10,
  },
};

export const experienceComingSoonStyles = {
  margin: "19px 0",
};

interface IStyledExperienceVisual {
  isInverted: boolean;
}

export const StyledExperienceVisual = styled.div<IStyledExperienceVisual>(
  ({ isInverted }) => ({
    display: "flex",
    maxWidth: "100%",
    alignSelf: "stretch",
    position: "relative",
    marginTop: -spacingXLg,
    marginLeft: -spacingMd,
    marginBottom: spacingMd,
    padding: "0 !important",

    [mq[1]]: {
      marginTop: spacingLg,
      marginBottom: -spacingLg,
      marginLeft: isInverted ? -spacingMd : 0,
      marginRight: isInverted ? 0 : -spacingMd,
      maxWidth: `calc(100% + ${spacingLg}px) !important`,
    },

    [mq[2]]: {
      marginTop: spacingSm * 4,
      marginBottom: -spacingSm * 4,
      marginLeft: isInverted ? -spacingSm * 4 : 0,
      marginRight: isInverted ? 0 : -spacingSm * 4,
      maxWidth: `calc(100% + ${spacingSm * 4}px) !important`,
    },

    [mq[3]]: {
      marginTop: spacingXLg,
      marginBottom: -spacingXLg,
      marginLeft: isInverted ? -spacingXLg : 0,
      marginRight: isInverted ? 0 : -spacingXLg,
      maxWidth: `calc(100% + ${spacingXLg}px) !important`,
    },

    "> div": {
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },

    img: {
      top: 0,
      left: 0,
      boxShadow,
      maxHeight: 300,
      display: "block",
      minWidth: "100%",
      minHeight: "100%",
      objectFit: "cover",
      borderTopLeftRadius: 30,

      [mq[1]]: {
        maxHeight: "100%",
        position: "absolute",
        borderTopLeftRadius: isInverted ? 0 : 50,
        borderTopRightRadius: isInverted ? 50 : 0,
      },
    },
  })
);
