import styled from "@emotion/styled";

import {
  spacingLg,
  spacingXLg,
  spacingXXLg,
} from "../../assets/styles/spacing";
import { mq } from "../../assets/styles/mediaqueries";
import { maxLayoutWidth } from "../../assets/styles/global";

interface IStyledSection {
  isCentered: boolean;
  backgroundColor: string;
}

const StyledSection = styled.section<IStyledSection>(
  ({ isCentered, backgroundColor }) => ({
    width: "100%",
    display: "flex",
    backgroundColor,
    padding: spacingLg,
    alignItems: "center",
    position: "relative",
    flexDirection: "column",

    "& + &": {
      paddingTop: 0,
    },

    [mq[1]]: {
      padding: spacingXLg,
    },

    [mq[2]]: {
      paddingTop: spacingXXLg,
      paddingBottom: spacingXXLg,
    },

    "> div": {
      width: "100%",
      maxWidth: maxLayoutWidth,
    },

    "h2, p": {
      textAlign: isCentered ? "center" : "left",
    },
  })
);

export default StyledSection;
