/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LanguageSelector from "../LanguageSelector";

import {
  headerStyles,
  headerContainerStyles,
  headerContainerLogoStyles,
} from "./styles";

import logo from "../../assets/images/logo-european_parliament.svg";

function Header() {
  const { t } = useTranslation();
  const { locale } = useParams();
  return (
    <header css={headerStyles}>
      <div css={headerContainerStyles}>
        <div css={headerContainerLogoStyles}>
          <a href={`https://www.europarl.europa.eu/portal/${locale}`}>
            <img
              src={logo}
              alt={t("logo.alt")}
              title={t("logo.alt")}
              width="60"
              height="40"
            />
          </a>
          <div>
            <span>{t("title")}</span>
            {t("subtitle")}
          </div>
        </div>

        <LanguageSelector />
      </div>
    </header>
  );
}

export default Header;
