import { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, HashRouter, Routes, Route } from "react-router-dom";

import Page404 from "./pages/404";
import HomePage from "./pages/home";
import BeAnMEPPrivacyPolicyPage from "./pages/beanmep-privacy-policy";

import Loading from "./components/Loading";

import globalStyles from "./assets/styles/global";
import isLocaleValid from "./utils/isLocalValid";
import imageManager from "./utils/ICFNext/ImageManager";
import PageAccessibilityVRPGStatement from "./pages/accessibility/vrpg-statement";
import PageAccessibility360ExperienceStatement from "./pages/accessibility/360-statement";
import PageAccessibilityBeAnMEPStatement from "./pages/accessibility/be-an-mep-statement";
import PageAccessibilityHubStatement from "./pages/accessibility/hub-statement";
import PageAccessibilityHistoryTourStatement from "./pages/accessibility/history-tour";

function ValidateLanguageHome() {
  const { locale } = useParams();
  if (locale && isLocaleValid(locale)) return <HomePage />;

  return <Page404 />;
}

function ValidateLanguageBeAnMEPProtectionPolicy() {
  const { locale } = useParams();
  if (locale && isLocaleValid(locale)) return <BeAnMEPPrivacyPolicyPage />;

  return <Page404 />;
}

function ValidateLanguageAccessibility360ExperienceStatement() {
  const { locale } = useParams();
  if (locale && isLocaleValid(locale))
    return <PageAccessibility360ExperienceStatement />;

  return <Page404 />;
}

function ValidateLanguageAccessibilityBeAnMEPStatement() {
  const { locale } = useParams();
  if (locale && isLocaleValid(locale))
    return <PageAccessibilityBeAnMEPStatement />;

  return <Page404 />;
}

function ValidateLanguageAccessibilityVRPGStatement() {
  const { locale } = useParams();
  if (locale && isLocaleValid(locale))
    return <PageAccessibilityVRPGStatement />;

  return <Page404 />;
}

function ValidateLanguageAccessibilityHubStatement() {
  const { locale } = useParams();
  if (locale && isLocaleValid(locale)) return <PageAccessibilityHubStatement />;

  return <Page404 />;
}

function ValidateLanguageAccessibilityHistoryTourStatement() {
  const { locale } = useParams();
  if (locale && isLocaleValid(locale))
    return <PageAccessibilityHistoryTourStatement />;
  return <Page404 />;
}

function RedirectHome() {
  const {
    i18n: { language },
  } = useTranslation();
  window.location.href = `/#/${language}`;

  return <HomePage />;
}

function App() {
  // Await image preload to complete before doing anything.
  // The Home Component is rendered *before* all images are loaded.
  // Removing the Home Modal introduced this issue,
  //   due to the fact that Modal lasted long enough to finish the preload without awaiting it.
  // Now we just await the image loader to finish before allowing the router to render.
  // @see https://icfnext-brussels.atlassian.net/browse/HUB-10
  const [isLoaded, setLoaded] = useState(false);
  useEffect(() => {
    const getLoaded = async () => {
      await imageManager.init();
      setLoaded(true);
    };
    getLoaded();
  }, []);

  const { i18n } = useTranslation();
  const { language } = i18n;

  if (!isLocaleValid(language)) i18n.language = "en";

  document.documentElement.setAttribute("lang", language);

  return (
    <HashRouter>
      {globalStyles}

      {/* <Warnings /> */}
      {isLoaded && (
        <Routes>
          <Route path="/" element={<RedirectHome />} />
          <Route path="/:locale" element={<ValidateLanguageHome />} />
          <Route
            path="/:locale/beanmep-privacy-policy"
            element={<ValidateLanguageBeAnMEPProtectionPolicy />}
          />
          <Route
            path="/:locale/360-experience-accessibility"
            element={<ValidateLanguageAccessibility360ExperienceStatement />}
          />
          <Route
            path="/:locale/be-an-mep-accessibility"
            element={<ValidateLanguageAccessibilityBeAnMEPStatement />}
          />
          <Route
            path="/:locale/virtual-role-play-game-accessibility"
            element={<ValidateLanguageAccessibilityVRPGStatement />}
          />
          <Route
            path="/:locale/accessibility"
            element={<ValidateLanguageAccessibilityHubStatement />}
          />
          <Route
            path="/:locale/history-tour-accessibility"
            element={<ValidateLanguageAccessibilityHistoryTourStatement />}
          />
          <Route path="/*" element={<Page404 />} />
        </Routes>
      )}
    </HashRouter>
  );
}

// here app catches the suspense from page in case translations are not yet loaded
export default function WrappedApp() {
  return (
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  );
}
