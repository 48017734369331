/* eslint-disable no-console */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Icon from "../Icon";

type AccessibilityMenuEntry = {
  experience: string;
  link: string;
};

function Menu() {
  const { i18n, t } = useTranslation();
  const entries: Array<AccessibilityMenuEntry> = [
    { experience: "360", link: "360-experience-accessibility" },
    { experience: "be-an-mep", link: "be-an-mep-accessibility" },
    {
      experience: "vrpg",
      link: "virtual-role-play-game-accessibility",
    },
    { experience: "hub", link: "accessibility" },
    { experience: "history-tour", link: "history-tour-accessibility" },
  ];
  const entriesI18n: Array<AccessibilityMenuEntry> = [];
  const location = useLocation();

  const setMenu = () => {
    entriesI18n.length = 0;
    entries
      .filter((e) => {
        const paths = location.pathname.split("/");
        return paths[paths.length - 1] !== e.link;
      })
      .forEach((e) => {
        entriesI18n.push({
          ...e,
          link: `/#/${i18n.language}/${e.link}`,
        });
      });
  };

  useEffect(() => {
    setMenu();
  }, [i18n.language, location.pathname]);

  setMenu();

  return (
    <div className="accessibility-menu">
      <h1 className="title">{t("accessibility.menu.title")}</h1>
      <div className="subtitle">{t("accessibility.menu.subtitle")}</div>
      <ul>
        {entriesI18n.map((entry) => (
          <li key={entry.experience}>
            <a href={entry.link}>
              <div className="title">
                {t(`accessibility.${entry.experience}.link`)}
              </div>
              <Icon
                icon="arrow_right"
                size={18}
                viewBox="0 0 227.096 227.096"
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
export default Menu;
