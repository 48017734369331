import styled from "@emotion/styled";

interface IStyledBackgroundShape {
  topPosition: number;
  sidePosition: number;
}

const StyledBackgroundShape = styled.div<IStyledBackgroundShape>(
  ({ topPosition, sidePosition }) => ({
    zIndex: 0,
    position: "absolute",
    pointerEvents: "none",
    top: `${topPosition}%`,
    left: `${sidePosition}%`,
  })
);

export default StyledBackgroundShape;
