/** @jsxImportSource @emotion/react */

import { iconStyles, StyledIconPath } from "./styles";

import iconPath from "../../assets/icons/icons";
import { defaultIconColor } from "../../assets/styles/colors";

interface IIconProps {
  icon: string;
  size?: number;
  color?: string;
  viewBox?: string;
}

function Icon({
  icon,
  size = 24,
  viewBox = "0 0 24 24",
  color = defaultIconColor,
}: IIconProps): React.ReactElement {
  return (
    <svg
      css={iconStyles}
      viewBox={viewBox}
      width={`${size}px`}
      height={`${size}px`}
    >
      <StyledIconPath
        fill={color}
        d={iconPath[icon as keyof typeof iconPath]}
      />
    </svg>
  );
}

export default Icon;
