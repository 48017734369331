import {
  brandColorWhite,
  defaultTextColor,
} from "../../../assets/styles/colors";
import { spacingMd, spacingSm } from "../../../assets/styles/spacing";

export const footerSocialsStyles = {
  width: "100%",
  paddingTop: spacingMd,
};

export const footerSocialsListStyles = {
  display: "flex",
  justifyContent: "center",
};

export const footerSocialsListItemLinkStyles = {
  width: 35,
  height: 35,
  display: "flex",
  paddingBottom: 0,
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  margin: `0 ${spacingSm}px`,
  border: `1px solid ${defaultTextColor}`,

  "&:hover": {
    backgroundColor: defaultTextColor,
    path: {
      fill: brandColorWhite,
    },
  },

  svg: {
    display: "block",
  },
};
