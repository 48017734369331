import { useTranslation, Trans } from "react-i18next";

export default function Intro() {
  const { t } = useTranslation();

  return (
    <section className="pageIntro">
      <div className="container">
        <h1>
          <Trans i18nKey="pageIntro.title" />
        </h1>
      </div>
      <div className="container">
        <h2 className="presentation">{t("pageIntro.presentation")}</h2>
        <div className="help presentation">{t("pageIntro.help")}</div>
      </div>
    </section>
  );
}
