//=========================================================
// Imports
//=========================================================

// App logger
import appLogger from "./AppLogger";

// Images to preload data
import {
    imagesToPreloadData,
} from '../../data/data-images-preload';

//=========================================================
// Class
//=========================================================

/**
 * Provides the functionality to:
 * - Preload images
 * - Get a preloaded image
 *
 * @class ImageManager
 */
class ImageManager {

    //=====================================================
    // Properties - General
    //=====================================================

    /**
     * Is the image manager switched on?
     * @private
     */
    isOn = true;

    /**
     * Has the image manager been initialised, yet?
     * NB: The method to get a preloaded image will only work
     * AFTER the image manager has been initialised.
     * @private
     */
    isInitialised = false;

    //=====================================================
    // Properties - Images
    //=====================================================

    // 
    /**
     * The DOM element that the images will be appended to.
     * @private
     */
    cache;

    //=====================================================
    // Init
    //=====================================================

    /**
     * Creates an instance of ImageManager.
     * @constructor
     */
    constructor() {

        // Log
        appLogger.logConstructor(ImageManager.name);
    }

    /**
     * Initialises the instance.
     * 
     * @return A promise that resolves when all images have been preloaded.
     * @public
     */
    init() {

        // If turned off, or if already initialised, abort
        if (!this.isOn || this.isInitialised) return;

        // Create a new DOM element for the preloaded images to get attached to, and add it to the DOM
        this.cache = document.createElement("div");
        this.cache.classList.add("images-preloader");
        document.body.appendChild(this.cache);

        // Promise that resolves when all images have been preloaded
        // eslint-disable-next-line
        const promiseToReturn = new Promise(resolve => {

            // When all images have been preloaded
            // eslint-disable-next-line
            Promise
                .all([
                    // Preload the images
                    this.preloadImages(),
                ])
                .then(() => {

                    // Set the initialised flag
                    this.isInitialised = true;

                    // Log
                    appLogger.logImage("ImageManager init finished.");

                    // Resolve the promise
                    resolve();
                });
        });

        // Return the promise
        return promiseToReturn;
    }

    /**
     * Creates all of the sfx howl objects upfront
     * (to ensure they are already loaded by the time we want to play them)
     * 
     * @return A promise that resolves when all sfx howls have been created.
     * @private
     */
    // Create an actual image from each image that was imported => So the browser caches it
    // https://stackoverflow.com/questions/3646036/preloading-images-with-javascript
    preloadImages() {

        // Promise that resolves when all sfx have been created
        let imagesPreloadedResolver;
        // eslint-disable-next-line
        const imagePreloadedPromise = new Promise((resolve) => {
            imagesPreloadedResolver = resolve;
        });

        // Loop through all the images
        this.i = 0;
        for (const [imageKey, imageValue] of Object.entries(imagesToPreloadData)) {

            // Create a new image
            let img = new Image();

            // Create a corresponding CSS variable
            // NB: This is in case we need to access it from within a css file,
            // e.g. to set a background-image.
            document.documentElement.style.setProperty(
                `--${imageKey}`,
                `url(${imageValue})`
            );

            // When the image has finished loading
            // eslint-disable-next-line no-loop-func
            img.onload = () => {
                this.onImageLoaded(
                    img,
                    imageKey,
                    imagesPreloadedResolver
                )
            };

            // Set its source
            img.alt="";
            img.src = imageValue;
        }

        // Return the promise
        return imagePreloadedPromise;
    };

    onImageLoaded(img, imageKey, imagesPreloadedResolver) {
        console.log("Image loaded:", imageKey)
        // Log
        appLogger.logImage("Image loaded:", imageKey);

        //alert("image is loaded");

        // Add it to the DOM
        this.cache.appendChild(img);

        this.i++;
        //console.log(this.i);

        // Check if this was the last howl to be created
        // Get the length
        const numTotal = Object.entries(imagesToPreloadData).length;
        //console.log("numTotal", numTotal);
        if (this.i === numTotal) {

            // When all images have been preloaded
            // Log
            appLogger.logImage("Image loading finished.");

            // Resolve the promise
            imagesPreloadedResolver();
        }
    }

    // Get the specified image
    getPreloadedImgSrc(imageValue) {
        // If manager is switched off, or not initialised, abort
        if (!this.isOn || !this.isInitialised) return;
        
        // Return the image path
        return imageValue;
    };
}

//=========================================================
// Exports
//=========================================================

// Instantiate
const imageManager = new ImageManager();

// Default export
export default imageManager;

// Named exports
// Helpers, so that other files who want to get an image
// can just import everything they need from here by using:
// 
// Image manager
//import imageManager, {imagesEnum} from "../../utils/ImageManager";
//
export {
    imagesToPreloadData as imagesEnum
};