import { useEffect } from "react";
import SupportDetector from "./ICFNext/warnings/SupportDetector";

const useMobileDetect = (setIsMobile: (arg0: boolean) => void): void => {
  const detectMobile = () => {
    const detector = new SupportDetector();
    setIsMobile(detector.detectMobile());
  };

  useEffect(() => {
    detectMobile();
  });
};

export default useMobileDetect;
