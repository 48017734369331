import styled from "@emotion/styled";

import {
  spacingSm,
  spacingLg,
  spacingXLg,
  spacingXXLg,
} from "../../assets/styles/spacing";
import { mq } from "../../assets/styles/mediaqueries";
import { boxShadow, brandColorWhiteWarm } from "../../assets/styles/colors";

interface IStyledColumns {
  columns: number[];
  isInverted: boolean;
}

const StyledColumns = styled.div<IStyledColumns>(({ columns, isInverted }) => {
  const styles = {
    boxShadow,
    zIndex: 5,
    display: "flex",
    position: "relative",
    marginTop: spacingXLg,
    borderTopLeftRadius: 30,
    flexDirection: "column",
    alignItems: "flex-start",
    backgroundColor: brandColorWhiteWarm,

    [mq[1]]: {
      marginTop: 0,
      width: "100%",
      minHeight: 400,
      alignItems: "stretch",
      borderTopLeftRadius: isInverted ? 0 : 50,
      borderTopRightRadius: isInverted ? 50 : 0,
      flexDirection: isInverted ? "row" : "row-reverse",
    },
  };

  const columnsWidth = columns.map((column, index) => {
    const columnIndex = index + 1;

    return {
      [`>:nth-of-type(${columnIndex})`]: {
        padding: spacingSm * 2,
        minWidth: "100%",

        [mq[1]]: {
          flex: column,
          minWidth: "auto",
          padding: spacingLg,
          maxWidth: `${column}%`,
          paddingLeft: spacingSm * 4,
          paddingRight: spacingSm * 4,
        },

        [mq[2]]: {
          padding: spacingXLg,
          paddingLeft: spacingXXLg,
          paddingRight: spacingXXLg,
        },

        [mq[3]]: {
          padding: spacingXXLg,
          paddingLeft: spacingSm * 10,
          paddingRight: spacingSm * 10,
        },
      },
    };
  });

  return Object.assign(styles, ...columnsWidth);
});

export default StyledColumns;
