const mainStyles = {
  width: "100%",
  display: "flex",
  margin: "0 auto",
  overflow: "hidden",
  alignItems: "stretch",
  position: "relative" as const,
  flexDirection: "column" as const,
};

export default mainStyles;
