import { mq } from "../../assets/styles/mediaqueries";
import { headerBackgroundColor } from "../../assets/styles/colors";
import { spacingSm, spacingMd, spacingLg } from "../../assets/styles/spacing";

export const headerStyles = {
  fontSize: "0.9em",
  paddingLeft: spacingMd,
  paddingRight: spacingMd,
  backgroundColor: headerBackgroundColor,

  [mq[1]]: {
    paddingLeft: spacingLg,
    paddingRight: spacingLg,
  },
};

export const headerContainerStyles = {
  margin: "auto",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column-reverse" as const,

  [mq[1]]: {
    alignItems: "center",
    flexDirection: "row" as const,
  },
};

export const headerContainerLogoStyles = {
  display: "flex",
  fontSize: "1.1em",
  lineHeight: "1.2em",
  alignItems: "center",
  paddingBottom: spacingMd,

  [mq[1]]: {
    lineHeight: "1.4em",
    paddingTop: spacingMd,
  },

  img: {
    maxWidth: 60,
    marginRight: spacingSm * 2,
  },

  span: {
    fontWeight: 600,
    display: "block",
  },
};
