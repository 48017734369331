/** @jsxImportSource @emotion/react */

import mainStyles from "./styles";

interface IMainProps {
  children: React.ReactNode;
}

function Main({ children }: IMainProps) {
  return <main css={mainStyles}>{children}</main>;
}

export default Main;
