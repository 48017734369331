/** @jsxImportSource @emotion/react */

import actionsStyles from "./styles";

interface IActionsProps {
  children: React.ReactNode;
}

function Actions({ children }: IActionsProps) {
  return <div css={actionsStyles}>{children}</div>;
}

export default Actions;
