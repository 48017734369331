const backgroundShapeVariants = (isAlignRight: boolean, delay: number) => ({
  hidden: {
    x: isAlignRight ? "100vw" : "-100vw",
  },

  visible: {
    x: "0vw",

    transition: {
      delay,
      duration: 0.7,
    },
  },
});

export default backgroundShapeVariants;
