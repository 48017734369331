import styled from "@emotion/styled";

import {
  brandColor,
  defaultTextColor,
  brandColorWhiteWarm,
} from "../../assets/styles/colors";
import { mq } from "../../assets/styles/mediaqueries";

const buttonCommonStyles = {
  fontWeight: 300,
  borderRadius: 50,
  cursor: "pointer",
  fontSize: "inherit",
  lineHeight: "inherit",
  textDecoration: "none",
  padding: "10px 30px 13px",
  textAlign: "center" as const,
  transition: "0.7s cubic-bezier(0.785, 0.135, 0.15, 0.86)",

  [mq[1]]: {
    fontSize: 18,
    lineHeight: "1.2em",
  },
};

interface IStyledButton {
  isSkip: boolean;
  isSecondary: boolean;
}

export const StyledButton = styled.button<IStyledButton>(
  ({ isSecondary, isSkip }) => ({
    ...buttonCommonStyles,
    backgroundColor: isSecondary
      ? isSkip
        ? "rgba(250,250,250,0.6)"
        : "transparent"
      : brandColor,
    color: isSecondary ? defaultTextColor : brandColorWhiteWarm,
    border: `1.5px solid ${isSecondary ? defaultTextColor : brandColor}`,

    "&:hover": {
      color: isSecondary ? brandColorWhiteWarm : brandColor,
      backgroundColor: isSecondary ? defaultTextColor : brandColorWhiteWarm,
    },
  })
);

interface IStyledLink {
  isSecondary: boolean;
}

export const StyledLink = styled.a<IStyledLink>(({ isSecondary }) => ({
  ...buttonCommonStyles,
  backgroundColor: isSecondary ? "transparent" : brandColor,
  color: isSecondary ? defaultTextColor : brandColorWhiteWarm,
  border: `1.5px solid ${isSecondary ? defaultTextColor : brandColor}`,

  "&:hover": {
    color: isSecondary ? brandColorWhiteWarm : brandColor,
    backgroundColor: isSecondary ? defaultTextColor : brandColorWhiteWarm,
  },
}));
