import { keyframes } from "@emotion/react";

export const loopKeyframes = keyframes`
  0% { left:100vw; }
  100% { left:-100vw; }
`;

export const spinKeyframes = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
