/** @jsxImportSource @emotion/react */

import { loadingStyles, loadingSpinStyles } from "./styles";

function Loading() {
  return (
    <div css={loadingStyles}>
      <div css={loadingSpinStyles} />
    </div>
  );
}

export default Loading;
