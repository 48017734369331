/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { HelmetProvider, Helmet } from "react-helmet-async";

import Main from "../components/Main";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Section from "../components/Section";

function Page404() {
  const { t } = useTranslation();

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {t("title")} {t("subtitle")}
          </title>
        </Helmet>
      </HelmetProvider>

      <Header />

      <Main>
        <Section>
          <h1>{t("error.notFound.title")}</h1>
          <br />
          <p>{t("error.notFound.text")}</p>
        </Section>
      </Main>

      <Footer isVisible />
    </>
  );
}

export default Page404;
