/** @jsxImportSource @emotion/react */

import StyledColumns from "./styles";

interface IColumnsProps {
  columns: number[];
  isInverted?: boolean;
  children: React.ReactNode;
}

function Columns({
  columns,
  children,
  isInverted = false,
}: IColumnsProps): React.ReactElement {
  return (
    <StyledColumns columns={columns} isInverted={isInverted}>
      {children}
    </StyledColumns>
  );
}

export default Columns;
