import styled from "@emotion/styled";

import { mq } from "../../assets/styles/mediaqueries";
import { spacingLg, spacingMd, spacingSm } from "../../assets/styles/spacing";
import { boxShadow, brandColorWhite } from "../../assets/styles/colors";

export const languageSelectorStyles = {
  border: 0,
  zIndex: 30,
  fontSize: "0.9em",
  alignSelf: "flex-end",
  paddingTop: spacingMd,
  paddingBottom: spacingMd,
  position: "relative" as const,

  [mq[1]]: {
    alignSelf: "center",
  },
};

export const languageSelectorSelectStyles = {
  border: 0,
  fontWeight: 400,
  fontSize: "inherit",
  fontFamily: "inherit",
  appearance: "none" as const,
  position: "relative" as const,
  backgroundColor: "transparent",

  span: {
    cursor: "pointer",
    paddingRight: spacingLg,
  },
};

interface IStyledLanguageSelectorSelectList {
  isOpen: boolean;
}

export const StyledLanguageSelectorSelectList =
  styled.ul<IStyledLanguageSelectorSelectList>(({ isOpen }) => ({
    right: 0,
    boxShadow,
    width: 200,
    padding: 0,
    top: "100%",
    listStyle: "none",
    overflow: "scroll",
    position: "absolute",
    height: isOpen ? "auto" : 0,
    backgroundColor: brandColorWhite,
    maxHeight: isOpen ? "75vh" : "0vh",
    transition: "0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86)",
  }));

interface IStyledLanguageSelectorSelectListItem {
  isActive: boolean;
}

export const StyledLanguageSelectorSelectListItem =
  styled.li<IStyledLanguageSelectorSelectListItem>(({ isActive }) => ({
    fontWeight: isActive ? 600 : 400,
    padding: `${spacingSm}px ${spacingMd}px`,
    cursor: isActive ? "default" : "pointer",
    pointerEvents: isActive ? "none" : "auto",
    transition: "0.7s cubic-bezier(0.785, 0.135, 0.15, 0.86)",

    "&:hover": {
      paddingLeft: spacingLg,
    },
  }));

interface IStyledLanguageSelectorIcon {
  isOpen: boolean;
}

export const StyledLanguageSelectorIcon =
  styled.div<IStyledLanguageSelectorIcon>(({ isOpen }) => ({
    right: 0,
    top: "calc(50% - 2px)",
    position: "absolute" as const,
    pointerEvents: "none" as const,
    transform: isOpen
      ? "translate(0%, -50%) rotate(180deg)"
      : "translate(0%, -50%) rotate(0deg)",
    transition: "0.7s cubic-bezier(0.785, 0.135, 0.15, 0.86)",
  }));
