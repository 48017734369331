import styled from "@emotion/styled";

export const iconStyles = {
  margin: "0 auto",
  display: "inline-block",
  verticalAlign: "middle",
};

interface IStyledIconPath {
  fill: string;
}

export const StyledIconPath = styled.path<IStyledIconPath>(({ fill }) => ({
  fill,
  transition: "all .5s ease",
}));
