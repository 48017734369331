export const brandColor = "#2C5288";
export const brandColorBlue = "#66E1Fa";
export const brandColorGreen = "#63DA79";
export const brandColorYellow = "#F4BB41";

export const brandColorWhite = "#FFFFFF";
export const brandColorBlack = "#0F0F0F";
export const brandColorWhiteWarm = "#F6F5EF";

export const brandColorGreyDark = "#232325";

export const defaultTextColor = brandColorGreyDark;
export const defaultIconColor = brandColorGreyDark;

export const headerBackgroundColor = brandColorWhite;
export const bodyBackgroundColor = brandColorWhiteWarm;
export const footerBackgroundColor = brandColorWhite;
export const modalBackgroundColor = "rgba(0, 0, 0, 0.2)";

export const boxShadow = "0px 5px 25px rgba(0, 0, 0, 0.1)";
