export default {
  caret:
    "M 1.6777346,5.0009476 A 1.733225,1.733225 0 0 0 0.52343775,5.4931351 1.733225,1.733225 0 0 0 0.49218775,7.944307 L 10.759766,18.47751 a 1.7333983,1.7333983 0 0 0 2.480468,0 L 23.507812,7.944307 a 1.733225,1.733225 0 0 0 -0.03125,-2.4511719 1.733225,1.733225 0 0 0 -2.451171,0.03125 L 12,14.784151 2.9746096,5.5243851 A 1.733225,1.733225 0 0 0 1.6777346,5.0009476 Z",
  close:
    "M18.3 5.71a.9959.9959 0 0 0-1.41 0L12 10.59 7.11 5.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z",
  social_facebook:
    "M 17.027347,0.00768215 V 3.9019813 c 0,0 -2.550305,-0.2879988 -3.194513,0.8096807 A 12.771735,12.771735 0 0 0 13.659782,8.3444885 H 17.05261 C 16.767137,9.8211137 16.557454,10.827846 16.346508,12.108683 H 13.634519 V 24 H 8.9406446 V 12.147841 H 6.94739 V 8.3444885 H 8.9166447 C 9.0151706,5.5655531 9.0530652,2.8043018 10.290955,1.3971499 11.665264,-0.18810648 12.990311,0.00768215 17.027347,0.00768215 Z",
  social_instagram:
    "m 19.845,5.5949998 a 1.439,1.439 0 0 1 -1.439,1.439 1.439,1.439 0 0 1 -1.439,-1.439 1.439,1.439 0 0 1 1.439,-1.439 1.439,1.439 0 0 1 1.439,1.439 z M 16.948,0.076 c -2.208,-0.103 -7.677,-0.098 -9.887,0 -1.942,0.091 -3.655,0.56 -5.036,1.941 -2.308,2.308 -2.013,5.418 -2.013,9.979 0,4.668 -0.26,7.706 2.013,9.979 2.317,2.316 5.472,2.013 9.979,2.013 4.624,0 6.22,0.003 7.855,-0.63 2.223,-0.863 3.901,-2.85 4.065,-6.419 0.104,-2.209 0.098,-7.677 0,-9.887 C 23.726,2.839 21.465,0.284 16.948,0.076 Z m 3.495,20.372 c -1.513,1.513 -3.612,1.378 -8.468,1.378 -5,0 -7.005,0.074 -8.468,-1.393 -1.685,-1.677 -1.38,-4.37 -1.38,-8.453 0,-5.525 -0.567,-9.504 4.978,-9.788 1.274,-0.045 1.649,-0.06 4.856,-0.06 l 0.045,0.03 c 5.329,0 9.51,-0.558 9.761,4.986 0.057,1.265 0.07,1.645 0.07,4.847 -0.001,4.942 0.093,6.959 -1.394,8.453 z M 12.004,5.838 c -3.403,0 -6.158,2.758 -6.158,6.158 0,3.403 2.758,6.158 6.158,6.158 3.403,0 6.158,-2.758 6.158,-6.158 0,-3.403 -2.758,-6.158 -6.158,-6.158 z m 0,10.155 c -2.209,0 -3.997,-1.789 -3.997,-3.997 0,-2.208 1.789,-3.997 3.997,-3.997 2.208,0 3.997,1.789 3.997,3.997 10e-4,2.208 -1.788,3.997 -3.997,3.997 z",
  social_linkedin:
    "M 2.882,0 C 1.291,0 0,1.291 0,2.882 0,4.473 1.291,5.791 2.882,5.791 4.473,5.791 5.764,4.473 5.764,2.882 5.763,1.291 4.472,0 2.882,0 Z M 0.396,7.977 H 5.372 V 24 H 0.396 Z M 23.994,24 V 23.999 H 24 v -8.802 c 0,-4.306 -0.927,-7.623 -5.961,-7.623 -2.42,0 -4.044,1.328 -4.707,2.587 h -0.07 V 7.976 H 8.489 v 16.023 h 4.97 v -7.934 c 0,-2.089 0.396,-4.109 2.983,-4.109 2.549,0 2.587,2.384 2.587,4.243 V 24 Z",
  social_twitter:
    "M22.46 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07 4.28 4.28 0 0 0 4 2.98 8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21 16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z",
  social_youtube:
    "M10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83-.25.9-.83 1.48-1.73 1.73-.47.13-1.33.22-2.65.28-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44-.9-.25-1.48-.83-1.73-1.73-.13-.47-.22-1.1-.28-1.9-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83.25-.9.83-1.48 1.73-1.73.47-.13 1.33-.22 2.65-.28 1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44.9.25 1.48.83 1.73 1.73z",
  social_mailto:
    "M24.818,260.333c-24.459,0-0.397-24.818-0.397-24.818l62.359-68.532 c6.065-6.663,17.769-16.78,24.612-11.199c6.842,5.58,15.98,16.888,22.344,23.268l12.896,12.907c6.913,6.119,12.232,0,12.232,0 " +
    "l13.195-12.999c6.418-6.326,15.61-17.362,22.463-22.496c6.853-5.134,18.509,4.629,24.557,11.308l61.994,68.5 " +
    "c24.47,24.062,0.397,24.062,0.397,24.062H24.818z M35.403,203.951c-6.065,6.668-14.925,18.264-20.913,24.998 " +
    "C7.729,236.548,0,242.318,0,229.009L0.294,95.311c0.022-9.013-0.228-26.2,5.929-23.116c2.29,1.148,5.423,3.535,9.676,7.794 " +
    "l50.464,41.288c6.973,5.706,16.801,12.689,20.016,17.34c2.029,2.932,2.959,6.342,0.022,9.29L35.403,203.951z M171.423,156.452 " +
    "c-6.967,5.716-14.539,13.826-18.406,13.837c-3.867,0.005-11.58-7.919-18.58-13.598L24.634,67.621 " +
    "c-7-5.673-19.058-17.1-13.065-20.163c4.329-2.214,11.895-2.176,11.895-2.176h258.029c23.23,0,11.417,11.471,11.417,11.471 " +
    "L171.423,156.452z M291.11,228.949c-5.999-6.728-14.865-18.33-20.924-24.998l-50.969-56.05c-2.953-2.948-2.029-6.358,0-9.29 " +
    "c3.214-4.65,13.048-11.629,20.027-17.334l37.845-30.959c6.973-5.706,16.143-15.039,22.295-18.123 " +
    "c6.152-3.084,5.885,14.109,5.907,23.116l0.326,133.698C305.621,242.318,297.881,236.548,291.11,228.949z",
  arrow_right:
    "M 152.835,39.285 146.933,45.183 211.113,109.373 0,109.373 0,117.723 211.124,117.723 146.933,181.902 152.835,187.811 227.096,113.55",
};
