/** @jsxImportSource @emotion/react */
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";
import { useTranslation } from "react-i18next";

import Icon from "../Icon";
import Modal from "../Modal";

import {
  modalShareStyles,
  modalShareListStyles,
  modalShareListButtonStyles,
} from "./styles";

interface IModalShareProps {
  id: string;
  link: string;
  isOpen?: boolean;
  toggleModal: () => void;
  subject?: string;
}

function ModalShare({
  id,
  link,
  isOpen = false,
  toggleModal,
  subject = "",
}: IModalShareProps) {
  const { t } = useTranslation();

  return (
    <Modal id={id} isSmall isOpen={isOpen} toggleModal={toggleModal}>
      <div css={modalShareStyles}>
        <h3>{t("modal.share.title")}</h3>

        <div css={modalShareListStyles}>
          <FacebookShareButton url={link} css={modalShareListButtonStyles}>
            <Icon icon="social_facebook" size={18} />
          </FacebookShareButton>
          <TwitterShareButton url={link} css={modalShareListButtonStyles}>
            <Icon icon="social_twitter" size={18} />
          </TwitterShareButton>
          <LinkedinShareButton url={link} css={modalShareListButtonStyles}>
            <Icon icon="social_linkedin" size={18} />
          </LinkedinShareButton>
          <EmailShareButton
            url={link}
            subject={subject}
            css={modalShareListButtonStyles}
          >
            <Icon
              icon="social_mailto"
              size={18}
              viewBox="0 0 305.616 305.616"
            />
          </EmailShareButton>
        </div>
      </div>
    </Modal>
  );
}

export default ModalShare;
