/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { HelmetProvider, Helmet } from "react-helmet-async";

import Main from "../components/Main";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Section from "../components/Section";

function PageBeAnMEPPrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {t("title")} {t("subtitle")}
          </title>

          <script
            defer
            type="text/javascript"
            data-tracker-name="ATInternet"
            data-value="https://www.europarl.europa.eu/website/webanalytics/ati-digital-citizen-journey.js"
            src="https://www.europarl.europa.eu/website/privacy-policy/privacy-policy.js"
          />
        </Helmet>
      </HelmetProvider>

      <Header />

      <Main>
        <Section isCentered>
          <h1>
            The Be an MEP <br />
            Virtual Reality Experience app
          </h1>
          <br />
          <br />
          <br />
          <h2>Privacy policy</h2>
          <p>
            The Be an MEP - Virtual Reality Experience app is not collecting nor
            processing any personal data of the user.
          </p>
        </Section>
      </Main>

      <Footer isVisible />
    </>
  );
}

export default PageBeAnMEPPrivacyPolicy;
