//=========================================================
// Imports
//=========================================================

// n/a

//=========================================================
// Log levels
//=========================================================

// Use this to enable/disable logging in general,
// as well as logging for specific levels.
const logLevels = {

    // Is logging enabled?
    isEnabled: true,

    constructor: {
        label: "Constructor",
        isEnabled: false
    },

    preloader: {
        label: "Preloader",
        isEnabled: true
    },

    image: {
        label: "Image",
        isEnabled: false
    },

};

//=========================================================
// Class
//=========================================================

/**
 * App logger
 * Responsible for logging messages during development.
 * 
 * Use this instead of console.log().
 */
class AppLogger {

    //=====================================================
    // Log to the console
    //=====================================================

    /**
     * Logs arguments to the console in general.
     * 
     * @public
     */
    log(...args) {

        // If logging is disabled, abort
        if (!logLevels.isEnabled) return;

        // Log to the console
        console.log(
            ...args
        );
    }

    /**
     * Logs specific info to the console, including specific check.
     *
     * @private
     */
    logSpecific(logLevel, ...args) {

        // If logging in general, or logging specific info is disabled, abort
        if (!logLevels.isEnabled || !logLevel.isEnabled) return;

        // Log to the console
        console.log(
            "[" + logLevel.label + "]",
            ...args
        );
    }

    /**
     * Logs arguments to the console for constructors.
     * 
     * @public
     */
    logConstructor(...args) {

        // Log specific info
        this.logSpecific(
            logLevels.constructor,
            ...args
        );
    }

    /**
     * Logs arguments to the console for http.
     * 
     * @public
     */
     logHttp(...args) {

        // Log specific info
        this.logSpecific(
            logLevels.http,
            ...args
        );
    }

    /**
     * Logs arguments to the console for image.
     * 
     * @public
     */
     logImage(...args) {

        // Log specific info
        this.logSpecific(
            logLevels.image,
            ...args
        );
    }

    /**
     * Logs arguments to the console for audio.
     * 
     * @public
     */
    logAudio(...args) {

        // Log specific info
        this.logSpecific(
            logLevels.audio,
            ...args
        );
    }

    /**
     * Logs arguments to the console for preloader.
     * 
     * @public
     */
    logPreloader(...args) {

        // Log specific info
        this.logSpecific(
            logLevels.preloader,
            ...args
        );
    }

}

//=========================================================
// Exports
//=========================================================

// Instantiate
const appLogger = new AppLogger();

// Default export
export default appLogger;