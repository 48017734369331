import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

type PictureSource = {
  width: number;
  url: string;
  media: string;
};

function Banner() {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState("");
  const [target, setTarget] = useState("");
  const [url, setURL] = useState("");
  const [sources, setSources] = useState<Array<PictureSource>>([]);

  useEffect(() => {
    const widths = [1220, 1020, 900, 640, 420, 400, 270];
    setTarget(`https://elections.europa.eu/video/${i18n.language}`);
    setURL(
      `/banner/${i18n.language}/EE24_DigitalJourney-web_banners_${widths[6]}px.png`
    );
    setSources(
      widths.map((w) => ({
        width: w,
        url: `/banner/${i18n.language}/EE24_DigitalJourney-web_banners_${w}px.png`,
        media: `(min-width: ${w}px)`,
      }))
    );
    setName(t("banner.alt"));
  }, [i18n.language, t]);

  return (
    <a href={target} target="_blank" rel="noreferrer">
      <picture className="ee2024__banner">
        {sources.map((s) => (
          <source
            srcSet={s.url}
            media={s.media}
            key={`banner-source-${s.width}`}
          />
        ))}
        <img src={url} alt={name} title={name} />
      </picture>
    </a>
  );
}

export default Banner;
