import { mq } from "../../assets/styles/mediaqueries";
import { spacingLg, spacingMd } from "../../assets/styles/spacing";

const actionsStyles = {
  display: "flex",
  paddingTop: spacingMd,
  flexDirection: "column" as const,

  [mq[1]]: {
    paddingTop: spacingLg,
  },

  "button + button, button + a, a + a, a + button": {
    marginTop: spacingMd,
  },
};

export default actionsStyles;
