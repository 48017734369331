/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";

import { footerNavStyles, footerNavListStyles } from "./styles";

import { wrapLinkList } from "../../../utils/wrapContent";

interface IFooterNavProps {
  isVisible: boolean;
}

function FooterNav({ isVisible }: IFooterNavProps) {
  const { t } = useTranslation();
  const yearN = new Date().getFullYear();
  return (
    <div css={footerNavStyles}>
      <ul css={footerNavListStyles}>
        {wrapLinkList(
          t(`footer.nav.list`, { returnObjects: true }),
          isVisible ? 0 : -1
        )}
        <li>{t("footer.copyright", { year: yearN })}</li>
      </ul>
    </div>
  );
}

export default FooterNav;
