export const experienceVariants = (isFirst: boolean, isInverted: boolean) => ({
  hidden: {
    opacity: 0,
    x: isInverted ? "30vw" : "-30vw",
  },

  visible: {
    x: "0vw",
    opacity: 1,

    transition: {
      duration: 0.5,
      delay: isFirst ? 1 : 0.5,
    },
  },
});

export const experienceImageVariants = (
  isFirst: boolean,
  isInverted: boolean
) => ({
  hidden: {
    opacity: 0,
    x: isInverted ? -500 : 500,
  },

  visible: {
    x: 0,
    opacity: 1,

    transition: {
      duration: 0.5,
      delay: isFirst ? 1.5 : 1,
    },
  },
});
