import styled from "@emotion/styled";

import {
  brandColorBlue,
  modalBackgroundColor,
} from "../../assets/styles/colors";
import { mq } from "../../assets/styles/mediaqueries";

interface IStyledModal {
  isIntro: boolean;
}

export const StyledModal = styled.div<IStyledModal>(({ isIntro }) => ({
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 20,
  position: "fixed",
  overflowY: "scroll",
  backgroundColor: isIntro ? brandColorBlue : modalBackgroundColor,
}));

interface IStyledModalcontent {
  isSmall: boolean;
}

export const StyledModalContent = styled.div<IStyledModalcontent>(
  ({ isSmall }) => ({
    top: "50%",
    left: "50%",
    width: "100%",
    display: "flex",
    overflow: "hidden",
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: "center",
    position: "absolute",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: brandColorBlue,
    maxWidth: isSmall ? 260 : "100%",
    height: isSmall ? "auto" : "100vh",
    transform: "translate(-50%, -50%)",
    borderTopRightRadius: isSmall ? 30 : 0,

    [mq[1]]: {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: isSmall ? 280 : "100%",
      borderTopRightRadius: isSmall ? 50 : 0,
    },

    [mq[2]]: {
      minHeight: 0,
      maxWidth: isSmall ? 300 : "100%",
    },
  })
);

export const modalCloseButtonStyles = {
  top: 20,
  right: 20,
  border: 0,
  cursor: "pointer",
  position: "absolute" as const,
};
