import parse from "html-react-parser";

import { Link } from "./interface";

export const wrapParagraph = (paragraphs: string[]): JSX.Element[] =>
  paragraphs.map((paragraph, index) => (
    <p key={`paragraph-${index.toString()}`}>{parse(paragraph)}</p>
  ));

export const wrapLinkList = (list: Link[], tabIndex?: number): JSX.Element[] =>
  list.map(({ link, label }, index) => (
    <li key={`listItem-${index.toString()}`}>
      <a href={link} target="_blank" rel="noreferrer" tabIndex={tabIndex ?? 0}>
        {label}
      </a>
    </li>
  ));
