/* eslint-disable no-console */
/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Icon from "../Icon";

import {
  languageSelectorStyles,
  languageSelectorSelectStyles,
  StyledLanguageSelectorSelectList,
  StyledLanguageSelectorSelectListItem,
  StyledLanguageSelectorIcon,
} from "./styles";

import languages from "../../config/lngs";
import isLocaleValid from "../../utils/isLocalValid";

function LanguageSelector() {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { locale } = useParams();
  const location = useLocation();
  const nbLocales = languages.length;

  const currentLocale = i18n.language;

  const [isOpen, setOpen] = useState(false);

  const updateRoute = (lang: string) => {
    const pathname = location.pathname.split("/").pop();
    navigate(`/${lang}/${pathname?.length !== 2 ? pathname : ""}`, {});
    document.documentElement.setAttribute("lang", lang);
  };

  const handleChangeLocale = (lang: string) => {
    setOpen(false);
    i18n.changeLanguage(lang);
    updateRoute(lang);
    window.location.reload();
  };

  useEffect(() => {
    if (locale && isLocaleValid(locale) && currentLocale !== locale)
      i18n.changeLanguage(locale);
  }, [locale]);
  /* if ((isOpen && e.key !== "Tab") || !isOpen) {
    setOpen(!isOpen);
  } */
  return (
    <div css={languageSelectorStyles}>
      <div css={languageSelectorSelectStyles}>
        <span
          tabIndex={0}
          role="button"
          aria-expanded={isOpen}
          onClick={() => setOpen(!isOpen)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setOpen(!isOpen);
            }
          }}
        >
          {languages.filter(({ code }) => code === currentLocale)[0].name}
        </span>
        {isOpen && (
          // only render the list if open.
          // this is for screen reader to avoid reading the language selector content while not displayed.
          <StyledLanguageSelectorSelectList isOpen={isOpen} tabIndex={-1}>
            {languages.map(({ name, code }, index) => (
              <StyledLanguageSelectorSelectListItem
                key={code}
                role="button"
                title={name}
                isActive={code === currentLocale}
                onClick={() => handleChangeLocale(code)}
                onBlur={() => index === nbLocales - 1 && setOpen(false)}
                onKeyDown={(e) => e.key === "Enter" && handleChangeLocale(code)}
                tabIndex={isOpen ? 0 : -1}
              >
                {name}
              </StyledLanguageSelectorSelectListItem>
            ))}
          </StyledLanguageSelectorSelectList>
        )}
      </div>
      <StyledLanguageSelectorIcon isOpen={isOpen}>
        <Icon icon="caret" size={15} />
      </StyledLanguageSelectorIcon>
    </div>
  );
}

export default LanguageSelector;
