/** @jsxImportSource @emotion/react */

import {
  motion,
  useAnimation,
  useTransform,
  useViewportScroll,
} from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

import StyledBackgroundShape from "./styles";
import backgroundShapeVariants from "./variants";

interface IBackgroundShapeProps {
  size: number;
  delay: number;
  color: string;
  topPosition: number;
  sidePosition: number;
  isAlignRight?: boolean;
  className?: string;
}

function BackgroundShape({
  size,
  color,
  delay,
  topPosition,
  sidePosition,
  isAlignRight = false,
  className = "background-shape",
}: IBackgroundShapeProps) {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const { scrollYProgress } = useViewportScroll();
  const y = useTransform(scrollYProgress, [0, 1], ["0%", "-50%"]);

  useEffect(() => {
    if (inView) controls.start("visible");
  }, [inView]);

  return (
    <StyledBackgroundShape
      ref={ref}
      topPosition={topPosition}
      sidePosition={sidePosition}
    >
      <motion.div
        style={{ y }}
        initial="hidden"
        animate={controls}
        variants={backgroundShapeVariants(isAlignRight, delay)}
        className={className}
      >
        <svg
          viewBox="0 0 24 12"
          width={`${size}px`}
          height={`${size / 2}px`}
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="12" x="0" y="0" ry="6" fill={color} />
        </svg>
      </motion.div>
    </StyledBackgroundShape>
  );
}

export default BackgroundShape;
