import { spinKeyframes } from "../../assets/styles/keyframes";
import { brandColorBlack, brandColorBlue } from "../../assets/styles/colors";

export const loadingStyles = {
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute" as const,
  backgroundColor: brandColorBlue,
};

export const loadingSpinStyles = {
  width: 35,
  height: 35,
  borderRadius: "50%",
  border: `5px solid ${brandColorBlack}`,
  borderTop: `5px solid ${brandColorBlue}`,
  animation: `${spinKeyframes} 1s linear infinite`,
};
