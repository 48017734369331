//=========================================================
// Imports
//=========================================================

// Sniffer, see https://github.com/wilddeer/Sniffer
import Sniffer from 'snifferjs';

// Feature.js, see https://github.com/viljamis/feature.js
// import * as feature from '../lib/feature';

/**
 * Support detector
 * Provides general functionality that can be reused for different projects
 *
 * @class SupportDetector
 */
class SupportDetector {

    //=====================================================
    // Properties
    //=====================================================

    /**
     * Sniffer
     * @private
     */
    Sniff = null;

    //=====================================================
    // Constructor
    //=====================================================

    /**
     * Creates an instance of this class.
     * @constructor
     */
    constructor() {

        // Initialise Sniffer
        this.Sniff = Sniffer(navigator.userAgent);

        // Log sniffer result object
        // console.log(this.Sniff);

        // Test Feature.js
        //console.log(feature.touch);
    }

    /**
     * Detects the device type
     * @public
     *
     * @returns {String} The device type name
     *
     * Device type names:
     * Desktop
     * Tablet
     * Mobile
     * Smart TV
     */
    // detectDeviceType() {

    //     // Available device types
    //     const deviceTypes = {
    //         desktop: "Desktop",
    //         tablet: "Tablet",
    //         mobile: "Mobile",
    //         tv: "Smart TV",
    //     };

    //     // Default to desktop
    //     let deviceType = deviceTypes.desktop;

    //     // Mobile
    //     // includes phones, tablets, e-book readers, portable game consoles, etc.
    //     if (this.Sniff.features.mobile) {
    //         deviceType = deviceTypes.mobile;

    //         if (window.innerWidth >= 414) {
    //             deviceType = deviceTypes.tablet;
    //         }
    //     }

    //     // Smart TV
    //     else if (this.Sniff.features.tv) {
    //         deviceType = deviceTypes.tv;
    //     }

    //     return deviceType;
    // };

    /**
     * Detects the operating system
     * @public
     *
     * @returns {Object} The operating system name, major version and minor version
     *
     * OS/Device names:
     * Windows [win]
     * Mac OS X [osx]
     * Ubuntu [ubuntu]
     * Fedora [fedora]
     * Windows Phone [winphone]
     * Android [android]
     * iOS [ios]
     * BlackBerry [blackberry]
     * Sailfish OS [sailfish]
     * Symbian [symbian]
     * Kindle [kindle] (Kindle Fire should be detected as Android)
     * PlayStation [playstation]
     * PlayStation Vita [psvita]
     * Nintendo DSi [dsi]
     * Nintendo 3DS [3ds]
     * New Nintendo 3DS [n3ds]
     * Viera [viera] (Panasonic Viera smart tv)
     */
    detectOperatingSystem() {

        // Use sniffer to determine os
        const {
            name,
            majorVersion,
            minorVersion,
            versionName,
        } = this.Sniff.os;

        //console.log(this.Sniff.os);

        // Object to be returned
        const os = {
            name,
            majorVersion,
            minorVersion,
            versionName,
        };

        return os;
    };

    /**
     * Detects Apple device
     * @public
     *
     * @returns {Boolean} True if Apple device, otherwise false
     */
    detectApple() {
        return (this.Sniff.os.name === 'ios') ?  true : false;
    };

    /**
     * Detects Android device
     * @public
     *
     * @returns {Boolean} True if Android device, otherwise false
     */
    detectAndroid() {
        return (this.Sniff.os.name === 'android') ?  true : false;
    };

    /**
     * Detects the browser
     * @public
     *
     * @returns {Object} The browser name, major version and engine
     *
     * Browser names:
     * Chrome [chrome]
     * Firefox [firefox]
     * IE [ie]
     * Edge [edge]
     * Safari [safari]
     * Opera [opera]
     * Opera Mini [operamini]
     * Opera Coast [coast]
     * Nokia Browser [nokiabrowser] (!= Nokia Xpress) — Symbian Belle phones
     * Ovi Browser a.k.a Nokia Xpress [ovi] — Nokia Asha, Series40 & Series60 phones, etc.
     * Sailfish Browser [sailfishbrowser]
     *
     * Browser engines:
     * WebKit [webkit]
     * Gecko [gecko]
     * Trident [trident]
     * Presto [presto]
     * EdgeHTML [edgehtml]
     */
    detectBrowser() {

        // Use sniffer to determine browser
        const {
            name,
            majorVersion,
            engine,
        } = this.Sniff.browser;

        // Object to be returned
        const browser = {
            name,
            majorVersion: majorVersion,
            engine,
        };

        return browser;
    };

    /**
     * Detects JavaScript support
     * @public
     *
     * @returns {Boolean} True if JavaScript is supported, otherwise false
     *
     * Q: Can this return the supported version as a string ?
     * A: Can't return a supported version as a string, but can detect
     * features of a version, to get the supported version in a
     * roundabout way.
     */
    detectJavaScriptSupport() {

        // TODO: ...
    };

    /**
     * Detects WebGL support
     * @public
     *
     * see https://en.wikipedia.org/wiki/WebGL
     *
     * @returns {Boolean} True if WebGL is supported, otherwise false
     */
    // detectWebGLSupport() {
    //     return feature.webGL;
    // };

    /**
     * Detects SVG support
     * @public
     *
     * @returns {Boolean} True if svg is supported, otherwise false
     */
    // detectSvgSupport() {
    //     return feature.svg;
    // };

    /**
     * Detects touch support
     * @public
     *
     * General rule of thumb:
     * - For layouts, assume everyone has a touchscreen
     * - For events and interactions, assume anyone may have a touchscreen
     *
     * TODO: Discuss: Since it's difficult to reliably check whether touch
     * is supported, should we just ask the user what the input type is?
     *
     * @returns {Boolean} True if touch is supported, otherwise false
     */
    detectTouchSupport() {

        let touch = false;

        // If Android or iOS
        // --------------------
        // This should account for all mobile phones and
        // tablets running Android, and all iPhones and
        // iPads running iOS, which as per the scope are
        // the only touch devices supported.
        // TODO: Do any small format laptops or tablet
        // computers use Android? e.g. Netbooks
        if (this.detectAndroid() || this.detectApple()) {
            touch = true;
        }

        return touch;
    };

    /**
     * Detects mobile
     * @public
     *
     * @returns {Boolean} True if mobile, otherwise false
     */
    detectMobile() {

        let mobile = false;

        // If Android or iOS
        // --------------------
        // This should account for all mobile phones and
        // tablets running Android, and all iPhones and
        // iPads running iOS, which as per the scope are
        // the only touch devices supported.
        // TODO: Do any small format laptops or tablet
        // computers use Android? e.g. Netbooks
        if (this.Sniff.features.mobile) {
            mobile = true;
        }

        // Newer versions of iPad have a different UA string
        // since Apple claimed 'desktop-class browsing with Safari on iPadOS'.
        // This check wich combines the UA string and touch points
        // should identify newer versions of iPad.
        // Example UA string: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0 Safari/605.1.15
        if (
            navigator.userAgent.match(/Mac/) &&
            navigator.maxTouchPoints &&
            navigator.maxTouchPoints > 2
        ) {
            mobile = true;
        }

        return mobile;
    };
}

//=========================================================
// Exports
//=========================================================

// Export
export default SupportDetector;