const languages = [
  { name: "BG - Български", code: "bg" },
  { name: "ES - Español", code: "es" },
  { name: "CS - čeština", code: "cs" },
  { name: "DA - Dansk", code: "da" },
  { name: "DE - Deutsch", code: "de" },
  { name: "ET - Eesti keel", code: "et" },
  { name: "EL - Ελληνικά", code: "el" },
  { name: "EN - English", code: "en" },
  { name: "FR - Français", code: "fr" },
  { name: "GA - Gaeilge", code: "ga" },
  { name: "HR - hrvatski", code: "hr" },
  { name: "IT - Italiano", code: "it" },
  { name: "LV - Latviešu valoda", code: "lv" },
  { name: "LT - Lietuvių kalba", code: "lt" },
  { name: "HU - magyar", code: "hu" },
  { name: "MT - Malti", code: "mt" },
  { name: "NL - Nederlands", code: "nl" },
  { name: "PL - Polski", code: "pl" },
  { name: "PT - Português", code: "pt" },
  { name: "RO - Română", code: "ro" },
  { name: "SK - Slovenčina", code: "sk" },
  { name: "SL - slovensčina", code: "sl" },
  { name: "FI - Suomi", code: "fi" },
  { name: "SV - Svenska", code: "sv" },
];

export default languages;
