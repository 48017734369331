/** @jsxImportSource @emotion/react */

import StyledSection from "./styles";

interface ISectionProps {
  children: React.ReactNode;
  backgroundColor?: string;
  isCentered?: boolean;
  className?: string;
}

function Section({
  children,
  isCentered = false,
  backgroundColor = "transparent",
  className = "",
}: ISectionProps) {
  return (
    <StyledSection
      backgroundColor={backgroundColor}
      isCentered={isCentered}
      className={className}
    >
      {children}
    </StyledSection>
  );
}

export default Section;
