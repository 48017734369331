import type { MutableRefObject } from "react";
import { useLayoutEffect, useRef, useState } from "react";

const useRefScrollProgress = (
  watch?: unknown
): [MutableRefObject<HTMLDivElement | null>, number, number] => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);

  useLayoutEffect(() => {
    const rect = ref?.current?.getBoundingClientRect();

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const offsetTop = scrollTop + (rect?.top || 0);

    setStart(offsetTop / document.body.clientHeight);
    setEnd((offsetTop + (rect?.height || 0)) / document.body.clientHeight);
  }, [watch]);

  return [ref, start, end];
};

export default useRefScrollProgress;
