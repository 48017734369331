import { Global } from "@emotion/react";

import defaultFont from "./fonts";
import { mq } from "./mediaqueries";
import { spacingSm, spacingMd, spacingLg } from "./spacing";
import { bodyBackgroundColor, defaultTextColor } from "./colors";

export const maxLayoutWidth = 1280;

const globalStyles = (
  <Global
    styles={{
      "*": {
        boxSizing: "border-box",
      },

      "html, body": {
        margin: 0,
        padding: 0,
      },

      body: {
        fontSize: 15,
        fontWeight: 300,
        lineHeight: "1.5em",
        color: defaultTextColor,
        fontFamily: defaultFont,
        backgroundColor: bodyBackgroundColor,

        [mq[1]]: {
          fontSize: 16,
        },

        [mq[2]]: {
          fontSize: 18,
        },
      },

      "#root": {
        display: "flex",
        minHeight: "100vh",
        flexDirection: "column",
        justifyContent: "space-between",
      },

      h1: {
        margin: 0,
        fontSize: 50,
        fontWeight: 900,
        lineHeight: "1.2em",
        textAlign: "center",
        color: defaultTextColor,
        fontFamily: defaultFont,
        marginBottom: spacingMd,

        [mq[1]]: {
          fontSize: 60,
        },

        [mq[2]]: {
          fontSize: 70,
        },
      },

      h2: {
        margin: 0,
        fontSize: 25,
        width: "100%",
        fontWeight: 300,
        lineHeight: "1.3em",
        color: defaultTextColor,
        marginBottom: spacingLg,

        [mq[1]]: {
          fontSize: 30,
        },

        [mq[2]]: {
          fontSize: 35,
        },
      },

      h3: {
        margin: 0,
        fontSize: 20,
        width: "100%",
        fontWeight: 300,
        lineHeight: "1.3em",
        color: defaultTextColor,
        marginBottom: spacingMd,

        [mq[1]]: {
          fontSize: 25,
        },

        [mq[2]]: {
          fontSize: 30,
        },
      },

      p: {
        marginTop: 0,
      },

      ul: {
        paddingLeft: 0,
        marginBottom: 0,
        listStyle: "none",
        paddingBottom: spacingMd,

        [mq[1]]: {
          paddingBottom: spacingLg,
        },

        li: {
          paddingBottom: spacingSm,
        },
      },

      img: {
        maxWidth: "100%",
      },

      a: {
        color: defaultTextColor,
      },

      button: {
        fontSize: "inherit",
        fontWeight: "inherit",
        fontFamily: "inherit",
        lineHeight: "inherit",
        backgroundColor: "transparent",
      },

      ".images-preloader": {
        top: 0,
        left: 0,
        opacity: 0,
        zIndex: -1000,
        position: "absolute",

        img: {
          position: "fixed",
        },
      },

      "#cookie-policy + #cookie-policy": {
        display: "none",
      },
    }}
  />
);

export default globalStyles;
