/** @jsxImportSource @emotion/react */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import FocusTrap from "focus-trap-react";

import Icon from "../Icon";

import {
  StyledModal,
  StyledModalContent,
  modalCloseButtonStyles,
} from "./styles";

interface IModalProps {
  id: string;
  isOpen: boolean;
  isSmall?: boolean;
  isIntro?: boolean;
  toggleModal?: () => void;
  children: React.ReactNode;
}

function Modal({
  id,
  isOpen,
  children,
  isIntro = false,
  isSmall = false,
  toggleModal = undefined,
}: IModalProps): React.ReactElement {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <FocusTrap>
          <StyledModal id={id} isIntro={isIntro}>
            <motion.div
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.2 } }}
            >
              <StyledModalContent isSmall={isSmall}>
                {toggleModal && (
                  <button
                    title="close"
                    type="button"
                    onClick={toggleModal}
                    css={modalCloseButtonStyles}
                  >
                    <Icon icon="close" size={28} />
                  </button>
                )}
                {children}
              </StyledModalContent>
            </motion.div>
          </StyledModal>
        </FocusTrap>
      )}
    </AnimatePresence>
  );
}

export default Modal;
