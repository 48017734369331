/** @jsxImportSource @emotion/react */
/* eslint-disable react/no-danger */
import { useTranslation } from "react-i18next";
import { HelmetProvider, Helmet } from "react-helmet-async";

import sanitizeHtml from "sanitize-html";

import Main from "../../components/Main";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import AccessibilityMenu from "../../components/Accessibility/menu";

function PageAccessibilityVRPGStatement() {
  const { t } = useTranslation();

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {t("title")} {t("subtitle")}
          </title>

          <script
            defer
            type="text/javascript"
            data-tracker-name="ATInternet"
            data-value="https://www.europarl.europa.eu/website/webanalytics/ati-digital-citizen-journey.js"
            src="https://www.europarl.europa.eu/website/privacy-policy/privacy-policy.js"
          />
        </Helmet>
      </HelmetProvider>

      <Header />

      <Main>
        <AccessibilityMenu />
        {(
          t("accessibility.vrpg.sections", {
            returnObjects: true,
            escapeValue: false,
          }) as []
        ).map(({ title, paragraphs }, index) => (
          <section
            className="accessibility-section"
            key={`section-vrpg-accessibility-statement-${index.toString()}`}
          >
            <h2>{title}</h2>
            {(paragraphs as []).map((text, pi) => (
              <p
                key={`section-vrpg-accessibility-statement-${index.toString()}-p-${pi.toString()}`}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(text, {
                    allowedTags: ["strong", "a", "ul", "ol", "li"],
                  }),
                }}
              />
            ))}
          </section>
        ))}
      </Main>

      <Footer isVisible />
    </>
  );
}

export default PageAccessibilityVRPGStatement;
