/** @jsxImportSource @emotion/react */

import FooterNav from "./Nav";
import FooterSocials from "./Socials";

import { footerStyles, footerContainerStyles } from "./styles";

interface IFooterProps {
  isVisible: boolean;
}

function Footer({ isVisible }: IFooterProps) {
  return (
    <footer css={footerStyles}>
      <div css={footerContainerStyles}>
        <FooterSocials isVisible={isVisible} />
        <FooterNav isVisible={isVisible} />
      </div>
    </footer>
  );
}

export default Footer;
