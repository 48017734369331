import { mq } from "../../../assets/styles/mediaqueries";
import { spacingMd } from "../../../assets/styles/spacing";
import { defaultTextColor } from "../../../assets/styles/colors";

export const footerNavStyles = {
  fontSize: "0.9em",
};

export const footerNavListStyles = {
  display: "flex",
  color: defaultTextColor,
  textAlign: "center" as const,
  flexDirection: "column" as const,

  [mq[1]]: {
    flexDirection: "row" as const,
  },

  a: {
    textDecoration: "none",
    color: defaultTextColor,
    padding: `0 ${spacingMd}px`,
    marginTop: "25px",

    "&:hover, &:active": {
      textDecoration: "underline",
    },
  },
};
